import { useState } from 'react';
import { MapPin, Phone, Mail } from 'lucide-react';

import { Input, Error, Textarea } from '../elements';
import { useRedirect } from '../../hooks';
import { controlOnErrors } from '../../utils';
import { contactStore } from '../../stores/';

import styles from '../../assets/styles/contact.module.scss';

const ContactForm = ({ minimal = false, page, hideItems = false }: any) => {
  const [hasSent, setHasSent] = useState(false);
  const { message } = contactStore;
  const { redirect } = useRedirect();

  const sendMessage = async () => {
    await controlOnErrors(contactStore.message);
    setHasSent(true);
    contactStore.sendMessage();
  };

  const resetForm = () => {
    setHasSent(false);
    contactStore.resetMessage();
  };

  const goToForm = () => {
    redirect('/contact');
  };

  const goToContact = () => {
    redirect('/contact');
  };

  return (
    <form className={styles.form}>
      {
        !hideItems && (
          <div className={styles.items}>
            <div className={styles.item}>
              <div className={styles.icon}>
                <MapPin />
                <strong>Locatie</strong>
              </div>

              <div>
                <p>
                  Sint-Janspolderdijk 14
                  <br />
                  9982 Sint-Jan-in-Eremo
                </p>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.icon}>
                <Phone />
                <strong>Bel ons</strong>
              </div>
              <div>
                {
                  page === undefined && (
                    <>
                      <p>
                        <a href="tel:+32477362377">+32 477 36 23 77 (Graanschuur)</a>
                      </p>
                      <p>
                        <a href="tel:+32479254460">+32 479 25 44 60 (Zoete kersen)</a>
                      </p>
                    </>
                  )
                }

                {
                  page === 'cherries' && (
                    <p>
                      <a href="tel:+32479254460">+32 479 25 44 60 (Zoete kersen)</a>
                    </p>
                  )
                }

                {
                  page === 'feestzaal' && (
                    <p>
                      <a href="tel:+32477362377">+32 477 36 23 77 (Graanschuur)</a>
                    </p>
                  )
                }
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.icon}>
                <Mail />
                <strong>E-mail</strong>
              </div>
              <p>
                <a onClick={goToContact} style={{ cursor: 'pointer' }}>info@engelendael.be</a>
              </p>
            </div>
          </div>
        )
      }


      {
        minimal && (
          <div className={styles.contactBtn} onClick={goToForm}>Contacteer ons</div>
        )
      }

      {
        !minimal && (
          <>
            {
              !hasSent && (
                <>
                  <section className={styles.inputs}>
                    <div className={styles.grid}>
                      <div>
                        <label htmlFor="name">Voornaam <strong>*</strong></label>
                        <Input entity={message} name="prename" placeholder="Vul hier uw voornaam in." />
                      </div>

                      <div>
                        <label htmlFor="name">Naam <strong>*</strong></label>
                        <Input entity={message} name="name" placeholder="Vul hier uw naam in." />
                      </div>

                      <div>
                        <label htmlFor="state">Gemeente <strong>*</strong></label>
                        <Input entity={message} name="state" placeholder="Vul hier uw gemeente in." />
                      </div>

                      <div>
                        <label htmlFor="address">Adres <strong>*</strong></label>
                        <Input entity={message} name="address" placeholder="Vul hier uw adres in." />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="email">E-mail <strong>*</strong></label>
                      <Input entity={message} name="email" type="email" placeholder="Vul hier uw e-mail in." />
                    </div>

                    <div>
                      <label htmlFor="telno">Telefoonnummer <strong>*</strong></label>
                      <Input type="tel" entity={message} name="telno" placeholder="Vul hier uw telefoonnummer in." />
                    </div>

                    <div>
                      <label htmlFor="message">Uw bericht <strong>*</strong></label>
                      <Textarea entity={message} name="message" placeholder="Vul hier uw bericht in." />
                    </div>

                    <div className={styles.btn} onClick={sendMessage}>Verstuur uw bericht</div>
                  </section>
                </>
              )
            }

            {
              hasSent && (
                <section className={styles.hasSent}>
                  <p className={styles.messageSuccessfull}>
                    <h2>Uw bericht is succesvol verzonden.</h2>
                    <span>
                      We zullen zo spoedig mogelijk uw vraag beantwoorden.
                    </span>
                  </p>


                  <div className={styles.btn} onClick={resetForm}>Verstuur nog een bericht</div>
                </section>
              )
            }
          </>
        )
      }

    </form>
  )
}

export default ContactForm;

