import { Suspense, lazy } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import { Header, Footer, Loading } from './partials';

import {
  News03062019,
  News11102018,
  News18012017,
  News20102016,
  News05062012,
  News20062020,
  News26042021,
  News25112022,
  News10082023,
} from './pages/';

const BanquetHall = lazy(() => import('./pages/BanquetHall'));
const Cherries = lazy(() => import('./pages/Cherries'));
const CherriesGallery = lazy(() => import('./pages/CherriesGallery'));
const News = lazy(() => import('./pages/News'));
const Gallery = lazy(() => import('./pages/Gallery'));
const Contact = lazy(() => import('./pages/Contact'));
const Privacy = lazy(() => import('./pages/Privacy'));
const PaymentRedirect = lazy(() => import('./pages/PaymentRedirect'));

const Router = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Header />
        <Routes>
          <Route path='/feestzaal' element={<BanquetHall />} />
          <Route path='/kersen' element={<Cherries />} />
          <Route path='/kersen/gallerij' element={<CherriesGallery />} />
          <Route path='/over-ons' element={<News />} />
          <Route path='/gallerij' element={<Gallery />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/privacy' element={<Privacy />} />

          <Route path="/news/03062019" element={<News03062019 />} />
          <Route path="/news/11102018" element={<News11102018 />} />
          <Route path="/news/18012017" element={<News18012017 />} />
          <Route path="/redirect/payment/:id" element={<PaymentRedirect />} />

          <Route path="/news/20102016" element={<News20102016 />} />
          <Route path="/news/05062012" element={<News05062012 />} />
          <Route path="/news/20062020" element={<News20062020 />} />
          <Route path="/news/26042021" element={<News26042021 />} />
          <Route path="/news/25112022" element={<News25112022 />} />
          <Route path="/news/10082023" element={<News10082023 />} />


          <Route path='*' element={<BanquetHall />} />
        </Routes>
        <Footer />
      </Suspense>
    </BrowserRouter>
  )
};

export default Router;


