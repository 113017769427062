import { useRedirect } from '../../hooks';
import styles from '../../assets/styles/news.module.scss';

import image from '../../assets/images/test.jpg';
import img from '../../assets/images/images11102018/flyer.png'

const News11102018 = () => {
  const { redirect } = useRedirect();

  return (
    <>
      <div className={styles.imgContainer}>
        <img src={image} alt="" />
      </div>

      <main className={styles.main}>
        <div style={{ position: 'relative' }}>
          <h1 className="title">Landschappen Sint-Laureins</h1>
        </div>

        <div className={styles.article}>
          <p>
            Er was veel belangstelling voor de lezing die op 3 oktober 2018
            werd georganiseerd door de vzw Landschappen Sint-Laureins.
            Dat onze streken nauwelijks 400 jaar geleden nog het strijdtoneel
            waren tegen de Spaanse bezetter spreekt tot de verbeelding. In
            het landschap zijn her en der de overblijfselen daarvan nog
            zichtbaar en stille getuigen uit een niet ver verleden.
          </p>
        </div>

        <div className={styles.imgContainer}>
          <img src={img} alt="flyer landschappen Sint-Laureins" style={{ height: 'inherit' }} />
        </div>
      </main>
    </>
  )
}

export default News11102018;
