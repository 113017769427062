import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Facebook, Instagram, MailOpen, Menu, X, ArrowUp } from 'lucide-react';

import engelendaelLogo from '../../assets/images/logo.svg';
import styles from '../../assets/styles/header.module.scss';
import { useWidthValidator, useRedirect } from '../../hooks';

const Header = () => {
  const { pathname } = useLocation();
  const { width } = useWidthValidator();
  const { redirect } = useRedirect();
  const [headerStatus, setHeaderStatus] = useState(true);
  const [isHeaderOpen, setIsHeaderOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const onScroll = function() {
    if (window.innerWidth > 1024) {
      if (window.scrollY > 350) {
        setHeaderStatus(false);
      } else if (window.scrollY < 500) {
        setHeaderStatus(true);
      }
    } else if (window.innerWidth < 1024) {
      if (window.scrollY > 220) {
        setHeaderStatus(false);
      } else if (window.scrollY < 220) {
        setHeaderStatus(true);
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.addEventListener('scroll', onScroll);
    };
  }, []);

  const isActive = (paths: string[]): (string | undefined) => {
    let style: string | undefined = "";
    paths.forEach(p => {
      if (p === pathname) {
        style = styles.active;
      }
    });

    return style;
  };

  if (width <= 768) {
    return (
      <header className={styles.header} style={{
        maxHeight: isHeaderOpen ? 300 : 100,
        backgroundColor: isHeaderOpen ? 'rgba(0, 0, 0, 0.9)' : 'rgba(0, 0, 0, 0.6)'
      }}>

        {
          !isHeaderOpen && (
            <div className={styles.svgContainer} onClick={() => setIsHeaderOpen(true)}>
              <Menu className={styles.menu} />
            </div>
          )
        }

        {
          isHeaderOpen && (
            <div className={styles.svgContainer} onClick={() => setIsHeaderOpen(false)}>
              <X className={styles.menu} />
            </div>
          )
        }

        <div className="logo" onClick={() => redirect('/')}>
          <img src={engelendaelLogo} alt="Engelendael logo" />
        </div>

        <MailOpen className={styles.email} onClick={() => redirect('/contact')} />

        {
          isHeaderOpen && (
            <>
              <ul className={styles.links}>
                <li
                  className={isActive(['/feestzaal', '/gallerij', '/'])}
                  onClick={() => {
                    setIsHeaderOpen(false);
                    redirect('/feestzaal');
                  }}
                >
                  <span>Feestzaal</span>
                </li>
                <li
                  className={isActive(['/kersen'])}
                  onClick={() => {
                    setIsHeaderOpen(false);
                    redirect('/kersen');
                  }}
                >
                  <span>Kersen</span>
                </li>
                <li
                  className={isActive(['/over-ons'])}
                  onClick={() => {
                    setIsHeaderOpen(false);
                    redirect('/over-ons');
                  }}
                >
                  <span>Over ons</span>
                </li>
              </ul>
            </>
          )
        }

        {
          !headerStatus && (
            <div className={styles.goToTop} onClick={() => window.scrollTo(0, 0)}>
              <ArrowUp />
            </div>
          )
        }
      </header>
    )
  }

  return (
    <header className={styles.header} style={{ maxHeight: headerStatus ? 180 : 60 }}>
      <div className="logo" onClick={() => redirect('/')} style={{ display: headerStatus ? 'block' : 'none' }}>
        <img src={engelendaelLogo} alt="Engelendael logo" />
      </div>

      <div className={styles.info}>
        <ul className={styles.socialMedia}>
          <li>
            <a href="https://www.facebook.com/engelendael/" target="_blank" rel="noopener noreferrer">
              <Facebook />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/hoeve.engelendael/?hl=nl" target="_blank" rel="noopener noreferrer">
              <Instagram />
            </a>
          </li>
        </ul>

        <ul className={styles.links}>
          <li
            className={isActive(['/feestzaal', '/gallerij', '/'])}
            onClick={() => {
              setIsHeaderOpen(false);
              redirect('/feestzaal');
            }}
          >
            <a href="#">Feestzaal</a>
          </li>
          <li
            className={isActive(['/kersen'])}
            onClick={() => {
              setIsHeaderOpen(false);
              redirect('/kersen');
            }}
          >
            <a href="#">Kersen</a>
          </li>
          <li
            className={isActive([
              '/over-ons',
              '/news/03062019',
              '/news/11102018',
              '/news/18012017',
              '/news/20102016',
              '/news/05062012',
              '/news/20062020',
              '/news/26042021',
              '/news/25112022'
            ])}
            onClick={() => {
              setIsHeaderOpen(false);
              redirect('/over-ons');
            }}
          >
            <a href="#">Over ons</a>
          </li>
        </ul>

        <ul className={styles.contactUL}>
          <li className={isActive(['/contact'])} onClick={() => redirect('/contact')}>
            <div className={styles.contact}>
              <MailOpen />
              <a href="#">Contacteer ons</a>
            </div>
          </li>
        </ul>
      </div>

      {
        !headerStatus && (
          <div className={styles.goToTop} onClick={() => window.scrollTo(0, 0)}>
            <ArrowUp />
          </div>
        )
      }
    </header>
  )
}

export default Header;

