import { useState } from 'react';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';

import styles from '../../assets/styles/gallery.module.scss';

const Gallery = ({ images }: any) => {
  const [selectedImage, setImage] = useState<undefined | { id: number, src: string }>();

  const openImageSlider = (img: { id: number, src: string }) => {
    setImage(img)
  };

  const closeDetail = () => {
    setImage(undefined);
  };

  const setPrevImg = () => {
    if (selectedImage !== undefined) {
      if (selectedImage.id === 1) {
        setImage(images[images.length - 1])
        return
      }

      setImage(images[selectedImage.id - 2]);
    }
  };

  const setNextImg = () => {
    if (selectedImage !== undefined) {
      if (selectedImage.id === images.length) {
        setImage(images[0])
        return
      }

      setImage(images[selectedImage.id]);
    }
  };


  return (
    <>
      {
        selectedImage !== undefined && (
          <>
            <div className={styles.overlay} onClick={closeDetail} />
            <div className={styles.detailView}>
              <X className={styles.close} onClick={closeDetail} />

              <img src={selectedImage.src} alt="Gekozen afbeelding in het groot" />

              <div className={styles.leftContainer} onClick={setPrevImg}>
                <ChevronLeft />
              </div>

              <div className={styles.rightContainer} onClick={setNextImg}>
                <ChevronRight />
              </div>
            </div>
          </>
        )
      }
      <section className={styles.gallery}>
        <ul className={styles.images}>
          {
            images.map((img: any) => {
              return (
                <li className={styles.image} key={img.id} onClick={() => openImageSlider(img)}>
                  <img src={img.src} alt="Afbeelding uit de gallerij" />
                </li>
              )
            })
          }
        </ul>
      </section>
    </>

  )
};


export default Gallery;
